import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Layout } from '../components'
import { black, grayOnBlack, white } from '../styling/colors'
import { ContactForm } from '../components'
import { When } from '../styling/when'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 88px);
`

const FormContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${When.Large} {
    height: 700px;
    max-width: 1330px;
  }
`

const SpaceFiller = styled.div`
  height: 70px;
`

export default function Contacts({ location }) {
  return (
    <Layout
      location={location}
      backgroundColor={white}
      style={{ maxHeight: 'unset', height: 'max-content' }}
      contentStyle={{ maxWidth: 'unset' }}
    >
      <Container>
        <FormContainer>
          <SpaceFiller />
          <ContactForm />
        </FormContainer>
      </Container>
    </Layout>
  )
}

const query = graphql`
  query {
    allStrapiSocialLinks {
      edges {
        node {
          facebook
          instagram
          linkedin
        }
      }
    }
  }
`
